import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export const roleGuard: CanActivateFn = (route, state) => {
  const roles = route.data['roles'] as string[];
  const authService = inject(AuthService);
  const matSnackBar = inject(MatSnackBar);

  const router = inject(Router);
  if (!authService.isLoggedIn()) {
    router.navigate(['/']);
    matSnackBar.open('Devi aver effettuato l\'accesso per accedere a questa pagina', 'OK', {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
    return false;
  }
  const userRoles = authService.getRoles();

  if (roles.some((role) => userRoles?.includes(role))) return true;
  router.navigate(['/']);
  matSnackBar.open('Non hai il permesso di accedere a questa pagina', 'OK', {
    duration: 3000,
    horizontalPosition: 'center',
    verticalPosition: 'top',
  });
  return false;
};