import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { jwtDecode } from 'jwt-decode';
import { Router } from '@angular/router';

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const token = authService.getToken();

  if (authService.getToken()) {
    if (token) {
      try {
        let decodedToken = jwtDecode(token);
        const isExpired =
          decodedToken && decodedToken.exp
            ? decodedToken.exp < Date.now() / 1000
            : false;

            
        if (isExpired) {
          console.log('token dismesso');          
          localStorage.removeItem('token');
          router.navigateByUrl('/');
        } else {
            const cloned = req.clone({
              headers: req.headers.set(
                'Authorization',
                'Bearer ' + authService.getToken()
              ),
            });

            return next(cloned);
        }
      } catch (e) {
        console.log('token non valido');
        localStorage.removeItem('token');
        router.navigateByUrl('/');
      }
    } else {
      console.log('no token');
      router.navigateByUrl('/');
    }

    return next(req);
  }

  return next(req);
};
