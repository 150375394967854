import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  Inject,
  inject,
  Injector,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterModule,
} from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import anime from 'animejs/lib/anime.es.js';
import { MatDialog } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { AuthService } from '../../services/auth.service';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule, DatePipe, NgOptimizedImage } from '@angular/common';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { LoaderService } from '../../loader/loader.service';
import { LoginComponent } from '../login/login.component';
import { isPlatformBrowser } from '@angular/common';
import { HostListener } from '@angular/core';
import { RegisterComponent } from '../register/register.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderComponent } from '../loader/loader.component';
import { CookieDialogComponent } from '../cookie-dialog/cookie-dialog.component';
import { CommonService } from '../../services/common.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  BrowserAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';
import {
  animate,
  AUTO_STYLE,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { OpenPopup } from '../../function/open-popup';

@Component({
  selector: 'app-navbar',
  standalone: true,
  providers: [
    MatDatepickerModule,
    provideAnimations(),
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
  ],
  imports: [
    LoginComponent,
    MatToolbarModule,
    NgOptimizedImage,
    CommonModule,
    RouterLink,
    RouterModule,
    MatMenuModule,
    MatButtonModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    LoaderComponent,
    CookieDialogComponent,
  ],
  animations: [
    trigger('inOutAnimation', [
      state('show', style({})),

      transition('void => show', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition('show => void', [animate(500, style({ opacity: 0 }))]),
    ]),
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent implements OnInit, AfterViewInit {
  commonService = inject(CommonService);
  authService = inject(AuthService);
  matSnackBar = inject(MatSnackBar);
  router = inject(Router);

  mobileshow: boolean = false;
  hiddenLogin: boolean = false;
  isPlatformBrowser: boolean;
  openMenu: boolean = false;
  openCookie: any = true;
  onloadInit: boolean = true;

  scrWidth: any;

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    public loaderService: LoaderService,
    public dialog: MatDialog,
    private injector: Injector,
    public activeroute: ActivatedRoute
  ) {
    this.isPlatformBrowser = isPlatformBrowser(platformId);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.mobileshow = window.innerWidth < 900;
  }

  isMobile() {
    const regex =
      /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
  }

  setNav() {
    this.hiddenLogin = true;
    let body = document.querySelector('body') as HTMLElement;
    let menu_btn = document.querySelector('.hamburger') as HTMLElement;
    let mobile_menu = document.querySelector('.mobile-nav') as HTMLElement;

    menu_btn.classList.toggle('is-active');
    menu_btn.classList.toggle('fixed');
    mobile_menu?.classList.toggle('is-active');

    if (this.isMobile()) {
      body.classList.toggle('noscroll');
    }
  }

  ngOnInit(): void {
    if (!this.isPlatformBrowser) return;
    this.onResize();

    this.activeroute.queryParamMap.subscribe((paramMap) => {
      var obj = paramMap.get('activeroute');

      if (obj != null) {
        this.openMenu = true;
      }
    });

    let account_btn = document.querySelector('#account_btn') as HTMLElement;

    account_btn?.addEventListener('click', function () {
      let user_detail = document.querySelector('#user_detail') as HTMLElement;
      user_detail?.classList.add('active-link');
    });

    if (this.mobileshow) {
    }

    const logoAnimation = anime.timeline({
      autoplay: true,
      delay: 0,
    });

    logoAnimation
      .add({
        targets: '#logo',
        translateY: [-100, 0],
        opacity: [0, 1],
        elasticity: 600,
        duration: 1600,
      })
      .add({
        targets: '#logo-text',
        translateX: ['-100%', 0],
        opacity: [0, 1],
        duration: 900,
        easing: 'easeOutExpo',
        offset: 900,
      });
  }

  ngAfterViewInit(): void {
    if (!this.isPlatformBrowser) return;
    this.onloadInit = false;

    this.openCookie = this.commonService.getCookieConsentValue() == 'Y';
  }

  isLoggedIn() {
    var ok = this.authService.isLoggedIn();
    return ok;
  }

  logout = () => {
    this.authService.logout();
    this.matSnackBar.open('Logout effettuato con', 'Chiudi', {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });

    this.isActive();
    this.router.navigate(['/']);
  };

  register() {
    this.isActive();
    OpenPopup(
      this.dialog,
      this.injector,
      true,
      'Registrati',
      RegisterComponent,
      '100%',
      true
    );
  }

  login() {
    this.isActive();
    OpenPopup(this.dialog, this.injector, true, 'Login', LoginComponent, true);
  }

  isActive() {
    document
      .querySelector('.hamburger')
      ?.classList.remove('is-active', 'fixed');
    document.querySelector('.mobile-nav')?.classList.remove('is-active');
  }
}
